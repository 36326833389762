const timingSafeEqual = (a, b) => {
  if (!(a instanceof Uint8Array)) {
    throw new TypeError('First argument must be a buffer');
  }
  if (!(b instanceof Uint8Array)) {
    throw new TypeError('Second argument must be a buffer');
  }
  if (a.length !== b.length) {
    throw new TypeError('Input buffers must have the same length');
  }
  const len = a.length;
  let out = 0;
  let i = -1;
  while (++i < len) {
    out |= a[i] ^ b[i];
  }
  return out === 0;
};
export default timingSafeEqual;