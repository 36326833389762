import { decode as base64url } from '../../runtime/base64url.js';
import decrypt from '../../runtime/decrypt.js';
import { JOSEAlgNotAllowed, JOSENotSupported, JWEInvalid } from '../../util/errors.js';
import isDisjoint from '../../lib/is_disjoint.js';
import isObject from '../../lib/is_object.js';
import decryptKeyManagement from '../../lib/decrypt_key_management.js';
import { encoder, decoder, concat } from '../../lib/buffer_utils.js';
import generateCek from '../../lib/cek.js';
import validateCrit from '../../lib/validate_crit.js';
import validateAlgorithms from '../../lib/validate_algorithms.js';
export async function flattenedDecrypt(jwe, key, options) {
  if (!isObject(jwe)) {
    throw new JWEInvalid('Flattened JWE must be an object');
  }
  if (jwe.protected === undefined && jwe.header === undefined && jwe.unprotected === undefined) {
    throw new JWEInvalid('JOSE Header missing');
  }
  if (jwe.iv !== undefined && typeof jwe.iv !== 'string') {
    throw new JWEInvalid('JWE Initialization Vector incorrect type');
  }
  if (typeof jwe.ciphertext !== 'string') {
    throw new JWEInvalid('JWE Ciphertext missing or incorrect type');
  }
  if (jwe.tag !== undefined && typeof jwe.tag !== 'string') {
    throw new JWEInvalid('JWE Authentication Tag incorrect type');
  }
  if (jwe.protected !== undefined && typeof jwe.protected !== 'string') {
    throw new JWEInvalid('JWE Protected Header incorrect type');
  }
  if (jwe.encrypted_key !== undefined && typeof jwe.encrypted_key !== 'string') {
    throw new JWEInvalid('JWE Encrypted Key incorrect type');
  }
  if (jwe.aad !== undefined && typeof jwe.aad !== 'string') {
    throw new JWEInvalid('JWE AAD incorrect type');
  }
  if (jwe.header !== undefined && !isObject(jwe.header)) {
    throw new JWEInvalid('JWE Shared Unprotected Header incorrect type');
  }
  if (jwe.unprotected !== undefined && !isObject(jwe.unprotected)) {
    throw new JWEInvalid('JWE Per-Recipient Unprotected Header incorrect type');
  }
  let parsedProt;
  if (jwe.protected) {
    try {
      const protectedHeader = base64url(jwe.protected);
      parsedProt = JSON.parse(decoder.decode(protectedHeader));
    } catch {
      throw new JWEInvalid('JWE Protected Header is invalid');
    }
  }
  if (!isDisjoint(parsedProt, jwe.header, jwe.unprotected)) {
    throw new JWEInvalid('JWE Protected, JWE Unprotected Header, and JWE Per-Recipient Unprotected Header Parameter names must be disjoint');
  }
  const joseHeader = {
    ...parsedProt,
    ...jwe.header,
    ...jwe.unprotected
  };
  validateCrit(JWEInvalid, new Map(), options?.crit, parsedProt, joseHeader);
  if (joseHeader.zip !== undefined) {
    throw new JOSENotSupported('JWE "zip" (Compression Algorithm) Header Parameter is not supported.');
  }
  const {
    alg,
    enc
  } = joseHeader;
  if (typeof alg !== 'string' || !alg) {
    throw new JWEInvalid('missing JWE Algorithm (alg) in JWE Header');
  }
  if (typeof enc !== 'string' || !enc) {
    throw new JWEInvalid('missing JWE Encryption Algorithm (enc) in JWE Header');
  }
  const keyManagementAlgorithms = options && validateAlgorithms('keyManagementAlgorithms', options.keyManagementAlgorithms);
  const contentEncryptionAlgorithms = options && validateAlgorithms('contentEncryptionAlgorithms', options.contentEncryptionAlgorithms);
  if (keyManagementAlgorithms && !keyManagementAlgorithms.has(alg) || !keyManagementAlgorithms && alg.startsWith('PBES2')) {
    throw new JOSEAlgNotAllowed('"alg" (Algorithm) Header Parameter value not allowed');
  }
  if (contentEncryptionAlgorithms && !contentEncryptionAlgorithms.has(enc)) {
    throw new JOSEAlgNotAllowed('"enc" (Encryption Algorithm) Header Parameter value not allowed');
  }
  let encryptedKey;
  if (jwe.encrypted_key !== undefined) {
    try {
      encryptedKey = base64url(jwe.encrypted_key);
    } catch {
      throw new JWEInvalid('Failed to base64url decode the encrypted_key');
    }
  }
  let resolvedKey = false;
  if (typeof key === 'function') {
    key = await key(parsedProt, jwe);
    resolvedKey = true;
  }
  let cek;
  try {
    cek = await decryptKeyManagement(alg, key, encryptedKey, joseHeader, options);
  } catch (err) {
    if (err instanceof TypeError || err instanceof JWEInvalid || err instanceof JOSENotSupported) {
      throw err;
    }
    cek = generateCek(enc);
  }
  let iv;
  let tag;
  if (jwe.iv !== undefined) {
    try {
      iv = base64url(jwe.iv);
    } catch {
      throw new JWEInvalid('Failed to base64url decode the iv');
    }
  }
  if (jwe.tag !== undefined) {
    try {
      tag = base64url(jwe.tag);
    } catch {
      throw new JWEInvalid('Failed to base64url decode the tag');
    }
  }
  const protectedHeader = encoder.encode(jwe.protected ?? '');
  let additionalData;
  if (jwe.aad !== undefined) {
    additionalData = concat(protectedHeader, encoder.encode('.'), encoder.encode(jwe.aad));
  } else {
    additionalData = protectedHeader;
  }
  let ciphertext;
  try {
    ciphertext = base64url(jwe.ciphertext);
  } catch {
    throw new JWEInvalid('Failed to base64url decode the ciphertext');
  }
  const plaintext = await decrypt(enc, cek, ciphertext, iv, tag, additionalData);
  const result = {
    plaintext
  };
  if (jwe.protected !== undefined) {
    result.protectedHeader = parsedProt;
  }
  if (jwe.aad !== undefined) {
    try {
      result.additionalAuthenticatedData = base64url(jwe.aad);
    } catch {
      throw new JWEInvalid('Failed to base64url decode the aad');
    }
  }
  if (jwe.unprotected !== undefined) {
    result.sharedUnprotectedHeader = jwe.unprotected;
  }
  if (jwe.header !== undefined) {
    result.unprotectedHeader = jwe.header;
  }
  if (resolvedKey) {
    return {
      ...result,
      key
    };
  }
  return result;
}