import { concat, uint64be } from '../lib/buffer_utils.js';
import checkIvLength from '../lib/check_iv_length.js';
import checkCekLength from './check_cek_length.js';
import crypto, { isCryptoKey } from './webcrypto.js';
import { checkEncCryptoKey } from '../lib/crypto_key.js';
import invalidKeyInput from '../lib/invalid_key_input.js';
import generateIv from '../lib/iv.js';
import { JOSENotSupported } from '../util/errors.js';
import { types } from './is_key_like.js';
async function cbcEncrypt(enc, plaintext, cek, iv, aad) {
  if (!(cek instanceof Uint8Array)) {
    throw new TypeError(invalidKeyInput(cek, 'Uint8Array'));
  }
  const keySize = parseInt(enc.slice(1, 4), 10);
  const encKey = await crypto.subtle.importKey('raw', cek.subarray(keySize >> 3), 'AES-CBC', false, ['encrypt']);
  const macKey = await crypto.subtle.importKey('raw', cek.subarray(0, keySize >> 3), {
    hash: `SHA-${keySize << 1}`,
    name: 'HMAC'
  }, false, ['sign']);
  const ciphertext = new Uint8Array(await crypto.subtle.encrypt({
    iv,
    name: 'AES-CBC'
  }, encKey, plaintext));
  const macData = concat(aad, iv, ciphertext, uint64be(aad.length << 3));
  const tag = new Uint8Array((await crypto.subtle.sign('HMAC', macKey, macData)).slice(0, keySize >> 3));
  return {
    ciphertext,
    tag,
    iv
  };
}
async function gcmEncrypt(enc, plaintext, cek, iv, aad) {
  let encKey;
  if (cek instanceof Uint8Array) {
    encKey = await crypto.subtle.importKey('raw', cek, 'AES-GCM', false, ['encrypt']);
  } else {
    checkEncCryptoKey(cek, enc, 'encrypt');
    encKey = cek;
  }
  const encrypted = new Uint8Array(await crypto.subtle.encrypt({
    additionalData: aad,
    iv,
    name: 'AES-GCM',
    tagLength: 128
  }, encKey, plaintext));
  const tag = encrypted.slice(-16);
  const ciphertext = encrypted.slice(0, -16);
  return {
    ciphertext,
    tag,
    iv
  };
}
const encrypt = async (enc, plaintext, cek, iv, aad) => {
  if (!isCryptoKey(cek) && !(cek instanceof Uint8Array)) {
    throw new TypeError(invalidKeyInput(cek, ...types, 'Uint8Array'));
  }
  if (iv) {
    checkIvLength(enc, iv);
  } else {
    iv = generateIv(enc);
  }
  switch (enc) {
    case 'A128CBC-HS256':
    case 'A192CBC-HS384':
    case 'A256CBC-HS512':
      if (cek instanceof Uint8Array) {
        checkCekLength(cek, parseInt(enc.slice(-3), 10));
      }
      return cbcEncrypt(enc, plaintext, cek, iv, aad);
    case 'A128GCM':
    case 'A192GCM':
    case 'A256GCM':
      if (cek instanceof Uint8Array) {
        checkCekLength(cek, parseInt(enc.slice(1, 4), 10));
      }
      return gcmEncrypt(enc, plaintext, cek, iv, aad);
    default:
      throw new JOSENotSupported('Unsupported JWE Content Encryption Algorithm');
  }
};
export default encrypt;