import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'challenge',
    loadChildren: () =>
      import('./modules/challenge/challenge.module').then((m) => m.ChallengeModule),
  },
  {
    path: 'otp',
    loadChildren: () =>
      import('./modules/otp/otp.module').then((m) => m.OtpModule),
  },
  {
    path: 'oob',
    loadChildren: () =>
      import('./modules/oob/oob.module').then((m) => m.OobModule),
  },
  {
    path: 'post',
    loadChildren: () =>
      import('./modules/post/post.module').then((m) => m.PostModule),
  },
  {
    path: '3ds-method',
    loadChildren: () =>
      import('./modules/method/method.module').then((m) => m.MethodModule),
  },
  {
    path: 'decoupled',
    loadChildren: () =>
      import('./modules/decoupled/decoupled.module').then((m) => m.DecoupledModule),
  },
  {
    path: 'webauthn',
    loadChildren: () =>
      import('./modules/webauthn/webauthn.module').then((m) => m.WebAuthnModule),
  },
  {
    path: 'result',
    loadChildren: () =>
      import('./modules/result/result.module').then(
        (component) => component.ResultModule
      ),
  },
  { path: '**', redirectTo: 'result', pathMatch: 'full' },
];
