import { decode as decodeBase64URL } from '../runtime/base64url.js';
import { fromSPKI, fromPKCS8, fromX509 } from '../runtime/asn1.js';
import asKeyObject from '../runtime/jwk_to_key.js';
import { JOSENotSupported } from '../util/errors.js';
import isObject from '../lib/is_object.js';
export async function importSPKI(spki, alg, options) {
  if (typeof spki !== 'string' || spki.indexOf('-----BEGIN PUBLIC KEY-----') !== 0) {
    throw new TypeError('"spki" must be SPKI formatted string');
  }
  return fromSPKI(spki, alg, options);
}
export async function importX509(x509, alg, options) {
  if (typeof x509 !== 'string' || x509.indexOf('-----BEGIN CERTIFICATE-----') !== 0) {
    throw new TypeError('"x509" must be X.509 formatted string');
  }
  return fromX509(x509, alg, options);
}
export async function importPKCS8(pkcs8, alg, options) {
  if (typeof pkcs8 !== 'string' || pkcs8.indexOf('-----BEGIN PRIVATE KEY-----') !== 0) {
    throw new TypeError('"pkcs8" must be PKCS#8 formatted string');
  }
  return fromPKCS8(pkcs8, alg, options);
}
export async function importJWK(jwk, alg) {
  if (!isObject(jwk)) {
    throw new TypeError('JWK must be an object');
  }
  alg || (alg = jwk.alg);
  switch (jwk.kty) {
    case 'oct':
      if (typeof jwk.k !== 'string' || !jwk.k) {
        throw new TypeError('missing "k" (Key Value) Parameter value');
      }
      return decodeBase64URL(jwk.k);
    case 'RSA':
      if (jwk.oth !== undefined) {
        throw new JOSENotSupported('RSA JWK "oth" (Other Primes Info) Parameter value is not supported');
      }
    case 'EC':
    case 'OKP':
      return asKeyObject({
        ...jwk,
        alg
      });
    default:
      throw new JOSENotSupported('Unsupported "kty" (Key Type) Parameter value');
  }
}