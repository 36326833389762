import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { ActivatedRoute, Router, provideRouter } from '@angular/router';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { routes } from './app.routes';
import { AuthInterceptor } from './auth/auth.interceptor';
import { AuthService } from './auth/auth.service';

export function initializeApp(route: ActivatedRoute): () => Promise<void> | void {
  return () => {
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [Router, AuthService] },
    { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [ActivatedRoute] },
  ]
};
