import { isJWK } from '../lib/is_jwk.js';
import { decode } from './base64url.js';
import importJWK from './jwk_to_key.js';
const exportKeyValue = k => decode(k);
let privCache;
let pubCache;
const isKeyObject = key => {
  return key?.[Symbol.toStringTag] === 'KeyObject';
};
const importAndCache = async (cache, key, jwk, alg, freeze = false) => {
  let cached = cache.get(key);
  if (cached?.[alg]) {
    return cached[alg];
  }
  const cryptoKey = await importJWK({
    ...jwk,
    alg
  });
  if (freeze) Object.freeze(key);
  if (!cached) {
    cache.set(key, {
      [alg]: cryptoKey
    });
  } else {
    cached[alg] = cryptoKey;
  }
  return cryptoKey;
};
const normalizePublicKey = (key, alg) => {
  if (isKeyObject(key)) {
    let jwk = key.export({
      format: 'jwk'
    });
    delete jwk.d;
    delete jwk.dp;
    delete jwk.dq;
    delete jwk.p;
    delete jwk.q;
    delete jwk.qi;
    if (jwk.k) {
      return exportKeyValue(jwk.k);
    }
    pubCache || (pubCache = new WeakMap());
    return importAndCache(pubCache, key, jwk, alg);
  }
  if (isJWK(key)) {
    if (key.k) return decode(key.k);
    pubCache || (pubCache = new WeakMap());
    const cryptoKey = importAndCache(pubCache, key, key, alg, true);
    return cryptoKey;
  }
  return key;
};
const normalizePrivateKey = (key, alg) => {
  if (isKeyObject(key)) {
    let jwk = key.export({
      format: 'jwk'
    });
    if (jwk.k) {
      return exportKeyValue(jwk.k);
    }
    privCache || (privCache = new WeakMap());
    return importAndCache(privCache, key, jwk, alg);
  }
  if (isJWK(key)) {
    if (key.k) return decode(key.k);
    privCache || (privCache = new WeakMap());
    const cryptoKey = importAndCache(privCache, key, key, alg, true);
    return cryptoKey;
  }
  return key;
};
export default {
  normalizePublicKey,
  normalizePrivateKey
};