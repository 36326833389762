export class JOSEError extends Error {
  constructor(message, options) {
    super(message, options);
    this.code = 'ERR_JOSE_GENERIC';
    this.name = this.constructor.name;
    Error.captureStackTrace?.(this, this.constructor);
  }
}
JOSEError.code = 'ERR_JOSE_GENERIC';
export class JWTClaimValidationFailed extends JOSEError {
  constructor(message, payload, claim = 'unspecified', reason = 'unspecified') {
    super(message, {
      cause: {
        claim,
        reason,
        payload
      }
    });
    this.code = 'ERR_JWT_CLAIM_VALIDATION_FAILED';
    this.claim = claim;
    this.reason = reason;
    this.payload = payload;
  }
}
JWTClaimValidationFailed.code = 'ERR_JWT_CLAIM_VALIDATION_FAILED';
export class JWTExpired extends JOSEError {
  constructor(message, payload, claim = 'unspecified', reason = 'unspecified') {
    super(message, {
      cause: {
        claim,
        reason,
        payload
      }
    });
    this.code = 'ERR_JWT_EXPIRED';
    this.claim = claim;
    this.reason = reason;
    this.payload = payload;
  }
}
JWTExpired.code = 'ERR_JWT_EXPIRED';
export class JOSEAlgNotAllowed extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JOSE_ALG_NOT_ALLOWED';
  }
}
JOSEAlgNotAllowed.code = 'ERR_JOSE_ALG_NOT_ALLOWED';
export class JOSENotSupported extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JOSE_NOT_SUPPORTED';
  }
}
JOSENotSupported.code = 'ERR_JOSE_NOT_SUPPORTED';
export class JWEDecryptionFailed extends JOSEError {
  constructor(message = 'decryption operation failed', options) {
    super(message, options);
    this.code = 'ERR_JWE_DECRYPTION_FAILED';
  }
}
JWEDecryptionFailed.code = 'ERR_JWE_DECRYPTION_FAILED';
export class JWEInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWE_INVALID';
  }
}
JWEInvalid.code = 'ERR_JWE_INVALID';
export class JWSInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWS_INVALID';
  }
}
JWSInvalid.code = 'ERR_JWS_INVALID';
export class JWTInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWT_INVALID';
  }
}
JWTInvalid.code = 'ERR_JWT_INVALID';
export class JWKInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWK_INVALID';
  }
}
JWKInvalid.code = 'ERR_JWK_INVALID';
export class JWKSInvalid extends JOSEError {
  constructor() {
    super(...arguments);
    this.code = 'ERR_JWKS_INVALID';
  }
}
JWKSInvalid.code = 'ERR_JWKS_INVALID';
export class JWKSNoMatchingKey extends JOSEError {
  constructor(message = 'no applicable key found in the JSON Web Key Set', options) {
    super(message, options);
    this.code = 'ERR_JWKS_NO_MATCHING_KEY';
  }
}
JWKSNoMatchingKey.code = 'ERR_JWKS_NO_MATCHING_KEY';
export class JWKSMultipleMatchingKeys extends JOSEError {
  constructor(message = 'multiple matching keys found in the JSON Web Key Set', options) {
    super(message, options);
    this.code = 'ERR_JWKS_MULTIPLE_MATCHING_KEYS';
  }
}
Symbol.asyncIterator;
JWKSMultipleMatchingKeys.code = 'ERR_JWKS_MULTIPLE_MATCHING_KEYS';
export class JWKSTimeout extends JOSEError {
  constructor(message = 'request timed out', options) {
    super(message, options);
    this.code = 'ERR_JWKS_TIMEOUT';
  }
}
JWKSTimeout.code = 'ERR_JWKS_TIMEOUT';
export class JWSSignatureVerificationFailed extends JOSEError {
  constructor(message = 'signature verification failed', options) {
    super(message, options);
    this.code = 'ERR_JWS_SIGNATURE_VERIFICATION_FAILED';
  }
}
JWSSignatureVerificationFailed.code = 'ERR_JWS_SIGNATURE_VERIFICATION_FAILED';