export interface ApiRequest<T> {
  RequestDateTime: string;
  RequestID: string;
  Data: T | null;
  Language?: string;
}

export interface ApiResponse<T> {
  RequestDateTime: string;
  RequestID: string;
  ResponseCode: string;
  ResponseMessage: string;

  Status?: string;
  Data: T;
  Description?: string;
}

export const buildRequest = <T>(Data: T | null): ApiRequest<T> => ({
  RequestDateTime: new Date().toISOString(),
  RequestID: window.crypto.randomUUID(),
  Data,
})
