import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, buildRequest } from '@core/api';
import { ConcatKDF, GenerateKeyPair, GetPublicKey } from '@core/crypto/ecdh';
import { Logger } from '@core/log';
import { environment } from '@environments/environment';
import { Observable, firstValueFrom, from } from 'rxjs';

interface HandshakeResponseData {
  PublicKey: string;
  Salt: string;
  EncryptionKey: Uint8Array;
  IV: string;
}

export type HandshakeData = HandshakeResponseData & { SessionID: string };

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private HandshakeData$!: Observable<HandshakeData>;
  private SessionId!: string;
  private ThreeDSServerTransID!: string;
  private IsHandshaked: boolean = false;

  constructor(private readonly http: HttpClient) { }

  handshake(): (Observable<HandshakeData>) {
    try {
      if (!this.IsHandshaked) {
        this.IsHandshaked = true;
        this.HandshakeData$ = from((async () => {
          const keyPair = await GenerateKeyPair();
          const publicKey = await GetPublicKey(keyPair);
          const data = buildRequest({ PublicKey: publicKey, ThreeDSServerTransID: this.ThreeDSServerTransID });
          const response = await firstValueFrom(this.http.post<ApiResponse<HandshakeResponseData>>(`${environment.apiUrl}/auth/handshake`, data))
          const cek = await ConcatKDF(keyPair, response.Data.PublicKey);
          const result = { SessionID: this.GetSessionId(), EncryptionKey: cek, IV: '', Salt: '', PublicKey: '' };
          return result;
        })())
      }
    } catch (ex) {
      Logger.log(ex)
      this.HandshakeData$ = from((async () => { return {} as HandshakeData })());
    }
    return this.HandshakeData$;
  }

  SetSessionId(id: string) {
    this.SessionId = id;
  }
  SetThreeDSServerTransID(id: string) {
    this.ThreeDSServerTransID = id;
  }

  GetSessionId() {
    return this.SessionId;
  }
  GetThreeDSServerTransID() {
    return this.ThreeDSServerTransID;
  }
}
